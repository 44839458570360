<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="text-blue-600 border-gray-600 rounded shadow-sm focus:ring-blue-500"
  >
</template>
